<template>
    <div class="ClassPackageAdmin">
        <div class="search-div flex">
            <div class="flex" style="flex: 1;justify-content: end">
                <div class="search-item">
                    <span>状态</span>
                    <a-select style="width: 100px" v-model="searchStatus">
                        <a-select-option value="">全部</a-select-option>
                        <a-select-option value="1">启用</a-select-option>
                        <a-select-option value="0">禁用</a-select-option>
                    </a-select>
                </div>

                <div class="search-item" style="white-space: nowrap">
                    <span>名称</span>
                    <a-input v-model="searchName" style="width: 200px;" placeholder="搜索名称"></a-input>
                </div>

                <a-button style="margin-right: 20px;" @click="_reset()">重置</a-button>
                <a-button type="primary" @click="_searchData">查询</a-button>
            </div>
            <div>
                <a-button type="primary" style="width:90px" @click="_showAddPage">新建</a-button>
            </div>
        </div>

        <div class="container">
            <a-table bordered :columns="tableColumns" :dataSource="tableData" :pagination="false">
                <div slot="ThumbUrl" slot-scope="text, record, index">
                    <img :src="text+'?imageView2/1/w/80/h/80'" style="width: 40px;height:40px;" alt="">
                </div>
                <div slot="State" slot-scope="text, record, index">
                    <span class="status-icon" :style="text=='禁用'?'background: #E81B1C':'background: #4199F3'"></span> {{text}}
                </div>
                <div slot="operation" slot-scope="text, record, index" style="width:100px">
                    <i class="iconfont icon-bianji icon-btn" @click="_setItem(index)"></i>
                    <a-popconfirm title="您确定要删除吗?" @confirm="_delTableRow(index)" okText="确定" cancelText="取消">
                        <i class="iconfont icon-shanchu- icon-btn"></i>
                    </a-popconfirm>
                </div>
            </a-table>
            <div class="pagination" v-if="tableDataCount>10" style="text-align: right">
                <a-pagination :total="tableDataCount" style="display: inline-block;margin-top:20px;" @change="_tablePage" />
            </div>
        </div>

        <!--添加课程-->
        <a-drawer title="新建课包" placement="right" width="600" :closable="false" @close="_addCourseClose" :visible="addCourseDrawer">
            <div style="padding-bottom: 55px;height: 100%">
                <div class="container alert-item">
                    <span style="">标题</span> <a-input class="alert-input" v-model="name"></a-input>
                </div>
                <div class="container alert-item">
                    <span style="">副标题</span> <a-input class="alert-input" v-model="SubTitle"></a-input>
                </div>
                <div class="container alert-item">
                    <span style="">课包图标</span> <a-button style="position: relative">上传图片 <input type="file" style="position: absolute;width: 100%;height: 100%;opacity: 0;top:0;left:0"  @change="_uploadCourceIcon($event,'bookIconUrl')"></a-button>
                </div>
                <div class="container alert-item" v-if="bookIconUrl">
                    <span style=""></span>
                    <img style="width:100px;height:100px;" :src="bookIconUrl" alt="">
                </div>

                <div class="container alert-item">
                    <span style="">详情长图</span> <a-button style="position: relative">上传图片 <input type="file" style="position: absolute;width: 100%;height: 100%;opacity: 0;top:0;left:0"  @change="_uploadCourceIcon($event,'PacksImageUrl')"></a-button>
                </div>
                <div class="container alert-item" v-if="PacksImageUrl">
                    <span style=""></span>
                    <img style="width:100px;" :src="PacksImageUrl" alt="">
                </div>

                <div class="container alert-item">
                    <span style="">课包类型</span>
                    <a-select style="width: 320px" @change="_changePacksType" v-model="PacksType">
                        <a-select-option value="在线课程">在线课程</a-select-option>
                        <a-select-option value="合同">合同</a-select-option>
                        <!-- <a-select-option value="产品">产品</a-select-option>
                        <a-select-option value="课程产品组合">课程产品组合</a-select-option> -->
                    </a-select>
                </div>

                <div class="container alert-item">
                    <span style="">课包套餐</span> <a-input class="alert-input" v-model="Packs" @click="_showAlert"></a-input>
                </div>

                <div class="container alert-item">
                    <span style="">标签</span> <a-input class="alert-input" v-model="Tag"></a-input>
                </div>

                <div class="container alert-item">
                    <span style="">原价</span> <a-input class="alert-input" v-model="price"></a-input>
                </div>
                <div class="container alert-item">
                    <span style="">售价</span> <a-input class="alert-input" v-model="thisPrice"></a-input>
                </div>
                <div class="container alert-item">
                    <span style="">课时数</span> <a-input class="alert-input" v-model="LessonNum"></a-input>
                </div>
                <div class="container alert-item">
                    <span style="">排序</span> <a-input class="alert-input" v-model="sort"></a-input>
                </div>

                <div class="container alert-item">
                    <span style="">开始时间</span>
                    <a-date-picker style="width: 320px;" v-model="StartDate" placeholder="请选择"/>
                </div>

                <div class="container alert-item">
                    <span style="">结束时间</span>
                    <a-date-picker style="width: 320px;" v-model="EndDate" placeholder="请选择"/>
                </div>

                <div class="container alert-item">
                    <span style="">限购对象</span>
                    <a-select style="width: 320px" @change="_changeObject" v-model="Objects">
                        <a-select-option value="会员">会员</a-select-option>
                        <a-select-option value="非会员">非会员</a-select-option>
                    </a-select>
                </div>

                <div class="container alert-item">
                    <span style="">限购次数</span>
                    <a-input class="alert-input" v-model="MaxBuy"></a-input>
                </div>

                <div class="container alert-item">
                    <span style="">课程编码</span> <a-input class="alert-input" v-model="courseCode"></a-input>
                </div>
                <div class="container alert-item">
                    <span style="vertical-align: top">课程描述</span> <textarea class="alert-input" v-model="courseDes" style="flex:1;height: 150px;border:1px solid #CCD1DC;border-radius: 4px;resize: none;padding: 5px;"></textarea>
                </div>

                <div class="container alert-item">
                    <span style="">是否发布</span>
                    <div class="alert-input" style="display: inline-block">
                        <div style="display: inline-block;width: 50%;vertical-align: middle">
                            <a-checkbox :checked="addCourseIssue ==1?true:false" @click="addCourseIssue = 1">是</a-checkbox>
                        </div>
                        <div style="display: inline-block;width: 50%;vertical-align: middle">
                            <a-checkbox :checked="addCourseIssue == 0? true: false" @click="addCourseIssue = 0">否</a-checkbox>
                        </div>
                    </div>
                </div>

                <div class="container alert-item">
                    <span style="">显示方式</span>
                    <div class="alert-input" style="display: inline-block;vertical-align: middle;margin-top:-4px;">
                        <div>
                            <a-checkbox v-model="showDetail">只显示详情页</a-checkbox>
                        </div>
                    </div>
                </div>

                <div class="container alert-item">
                    <span style="">展示位置</span>
                    <div class="alert-input" style="display: inline-block;vertical-align: middle;margin-top:-4px;">
                        <div>
                            <a-checkbox-group v-model="checkedList" :options="checkboxOptions"/>
                        </div>
                    </div>
                </div>


                <div class="alert-footer">
                    <a-button style="margin-right:25px" @click="_addCourseClose">取消</a-button>
                    <a-button type="primary" @click.stop="_addCourseSave">确定</a-button>
                </div>
            </div>
        </a-drawer>

        <a-drawer title="选择课程套餐" placement="right" width="700" :closable="false" @close="_closeAlert" :visible="selectCourse">

            <a-table bordered :columns="alertColumns" :dataSource="alertData" :showAlertInfo="true" :pagination="false" :rowSelection="{selectedRowKeys: bookSelectedRowKeys, onChange: bookOnChange}">
                <div slot="ThumbUrl" slot-scope="text, record, index">
                    <img :src="text+'?imageView2/1/w/60/h/60'" style="width: 30px;height: 30px;" alt="">
                </div>
                <div slot="status" slot-scope="text, record, index">
                    <span class="status-icon" :style="text=='禁用'?'background: #E81B1C':'background: #4199F3'"></span> {{text}}
                </div>
            </a-table>

            <div class="alert-footer">
                <a-button style="margin-right:25px" @click="_closeAlert">取消</a-button>
                <a-button type="primary" @click="_saveSelect">确定</a-button>
            </div>
        </a-drawer>
    </div>
</template>

<script>
    export default {
        name: "ClassPackageAdmin",
        data(){
            return {
                searchStatus: '',
                searchName: '',
                checkedList: [],
                checkboxOptions: [],
                tableColumns: [
                    {
                        title: '序号',
                        dataIndex: 'sNumber',
                        width: 70,
                        align: 'center',
                    },
                    {
                        title: '缩略图',
                        dataIndex: 'ThumbUrl',
                        width: 110,
                        align: 'center',
                        scopedSlots: { customRender: 'ThumbUrl' },
                    },
                    {
                        title: '标题',
                        dataIndex: 'Title',
                    },
                    {
                        title: '课包类型',
                        dataIndex: 'Type',
                        width: 120,
                        align: 'center'
                    },
                    {
                        title: '限购对象',
                        dataIndex: 'Object',
                        width: 120,
                        align: 'center'
                    },
                    {
                        title: '套餐',
                        dataIndex: 'Packs',
                        width: 250,
                        align: 'center'
                    },
                    {
                        title: '原价',
                        dataIndex: 'OriginalPrice',
                        width: 120,
                        align: 'center'
                    },
                    {
                        title: '售价',
                        dataIndex: 'Price',
                        width: 120,
                        align: 'center'
                    },
                    {
                        title: '课时数',
                        dataIndex: 'LessonNum',
                        width: 120,
                        align: 'center'
                    },
                    {
                        title: '状态',
                        dataIndex: 'State',
                        width: 110,
                        align: 'center',
                        scopedSlots: { customRender: 'State' },
                    },
                    {
                        title: '操作',
                        dataIndex: 'operation',
                        width: 100,
                        align: 'center',
                        scopedSlots: { customRender: 'operation' },
                    }
                ],
                tableData: [],
                tableDataCount: 0,
                addCourseDrawer: false,
                selectCourse: false,

                name: '',
                Packs: '',
                addCourseIssue: 0,
                bookIconUrl: '',
                price: '',
                thisPrice: '',
                sort: '',
                courseCode: '',
                courseDes: '',

                setItem: -1,
                alertColumns: [
                    {
                        title: '序号',
                        dataIndex: 'sNumber',
                        width: 70,
                        align: 'center',
                    },
                    {
                        title: '缩略图',
                        dataIndex: 'ThumbUrl',
                        width: 110,
                        align: 'center',
                        scopedSlots: { customRender: 'ThumbUrl' },
                    },
                    {
                        title: '课程名称',
                        dataIndex: 'Title',
                        align: 'center',
                    },
                    {
                        title: '分类',
                        dataIndex: 'Age',
                        align: 'center'
                    },
                    {
                        title: '价格',
                        dataIndex: 'Price',
                        width: 120,
                        align: 'center'
                    },
                    {
                        title: '状态',
                        dataIndex: 'State',
                        width: 110,
                        align: 'center',
                        scopedSlots: { customRender: 'status' },
                    },
                ],
                alertData: [],
                bookSelectedRowKeys: [],
                bookSelectedRow: [],
                PacksList: [],
                PacksType:'',
                Tag:'',
                SubTitle:'',
                StartDate:null,
                EndDate:null,
                Objects:'',
                MaxBuy:1,
                PacksImageUrl:'',
                LessonNum:0,
                showDetail: true,
            }
        },
        created(){
            this._infoData('','',1);
        },
        methods: {
            _saveSelect(){
                let str = [];
                this.PacksList = this.bookSelectedRow;
                this.PacksList.map(res=>{
                    console.log(res.Title)
                    str.push(res.Title)
                });
                this.Packs = str.join(',');
                this.selectCourse = false;
            },
            bookOnChange(selectedRowKeys, selectedRows){
                //选中的数据的key
                this.bookSelectedRowKeys = selectedRowKeys;
                this.bookSelectedRow = selectedRows;
            },
            _showAlert(){
                this.selectCourse = true;
                this.$axios.get(5836184,{

                },res=>{
                    if(res.data.code==1){
                        this.alertData = res.data.data;
                        this.PacksList.map(res1=>{
                            this.alertData.map((res2,index)=>{
                                if(res1.Id == res2.Id){
                                    this.bookSelectedRowKeys.push(index)
                                }
                            })
                        });
                    }else{
                        this.$message.success(res.data.message);
                    }
                });
            },
            _closeAlert(){
                this.selectCourse = false;
            },
            _setItem(i){
                this.setItem = i;
                this.$axios.get(5836182,{
                    PackId: this.tableData[i].Id,
                },res=>{
                    if(res.data.code==1){
                        this.addCourseDrawer = true;
                        let data = res.data.data;
                        this.name = data.Title;
                        this.bookIconUrl = data.Thumb;
                        this.PacksList = data.Packs;
                        this.price = data.Cost;
                        this.thisPrice = data.Price;
                        this.sort = data.Sort;
                        this.courseCode = data.Code;
                        this.courseDes = data.Content;
                        this.addCourseIssue = data.Status;
                        this.SubTitle = data.SubTitle;
                        this.PacksType = data.Type;
                        this.Tag = data.Tag;
                        this.StartDate = data.StartDate?this.__moment__(data.StartDate):'';
                        this.EndDate = data.EndDate?this.__moment__(data.EndDate):'';
                        this.Objects = data.Object;
                        this.MaxBuy = data.MaxBuy;
                        this.PacksImageUrl = data.Image;
                        this.LessonNum = data.LessonNum;
                        this.showDetail = data.ShowType == 1 ? true : false;
                        this.checkedList = JSON.parse(data.ShowAddr);
                        let list  = data.Packs;
                        let arr = [];
                        list.map(res=>{
                            arr.push(res.Title);
                        });
                        this.Packs = arr.join(',')
                    }else{

                    }
                });
            },
            _infoData(Title,State,Page){
                this.$axios.get(5836180,{
                    Page: Page,
                    State: State,
                    Title: Title,
                },res=>{
                    if(res.data.code==1){
                        this.tableData = res.data.data;
                        this.tableDataCount = res.data.count
                    }else{
                        this.tableData = [];
                        this.tableDataCount = 0;
                    }
                    this.checkboxOptions = res.data.ShowAddr;
                });
            },
            _tablePage(page){
                this._infoData(this.searchName,this.searchStatus,page);
            },
            _addCourseSave(){
                this.$axios.post(5836183,{
                    Id: this.setItem == -1 ? '' : this.tableData[this.setItem].Id,
                    Title: this.name,
                    ThumbUrl: this.bookIconUrl,
                    OriginalPrice: this.price,
                    Sort: this.sort,
                    Price: this.thisPrice,
                    Code: this.courseCode,
                    State: this.addCourseIssue,
                    Intro: this.courseDes,
                    ComId: '',
                    Course_info: JSON.stringify(this.PacksList),
                    Type:this.PacksType,
                    Tag:this.Tag,
                    SubTitle:this.SubTitle,
                    StartDate:this.StartDate?this.__moment__(this.StartDate).format('YYYY-MM-DD'):'',
                    EndDate:this.EndDate?this.__moment__(this.EndDate).format('YYYY-MM-DD'):'',
                    Object:this.Objects,
                    MaxBuy:this.MaxBuy,
                    Image:this.PacksImageUrl,
                    LessonNum:this.LessonNum,
                    ShowType: this.showDetail?1:0,
                    ShowAddr: this.checkedList.length > 0 ? JSON.stringify(this.checkedList): ''
                },res=>{
                    if(res.data.code==1){
                        this.$message.success(res.data.message);
                        if(this.setItem == -1){
                            let item = {
                                Id: res.data.id,
                                sNumber: this.tableData.length + 1,
                                ThumbUrl: this.bookIconUrl,
                                Title: this.name,
                                Packs: this.Packs,
                                OriginalPrice: this.price,
                                Price: this.thisPrice,
                                State: this.addCourseIssue == 1 ? '启用' : '禁用',
                                Code: this.courseCode,
                                Intro: this.courseDes
                            };
                            this.tableData.push(item);
                            this._addCourseClose();
                        }else{
                            this.tableData[this.setItem].ThumbUrl = this.bookIconUrl;
                            this.tableData[this.setItem].Title = this.name;
                            this.tableData[this.setItem].OriginalPrice = this.price;
                            this.tableData[this.setItem].Price = this.thisPrice;
                            this.tableData[this.setItem].State = this.addCourseIssue == 1 ? '启用' : '禁用';
                            this.tableData[this.setItem].Code = this.courseCode;
                            this.tableData[this.setItem].Intro = this.courseDes;
                            this._addCourseClose();
                        }
                    }else{
                        this.$message.error(res.data.message);
                    }
                });
            },
            _uploadCourceIcon(e,name){
                this.__uploadFile__(e.target.files).then(res=>{
                    if ('bookIconUrl' == name) {
                        this.bookIconUrl = res[0].FileUrl;
                    } else {
                        this.PacksImageUrl = res[0].FileUrl;
                    }
                });
            },
            _showAddPage(){
                this.addCourseDrawer = true;
            },
            _addCourseClose(){
                this.addCourseDrawer = false;
                this.name = '';
                this.bookIconUrl = '';
                this.Packs = '';
                this.price = '';
                this.thisPrice = '';
                this.sort = '';
                this.courseCode = '';
                this.courseDes = '';
                this.addCourseIssue = 0;
                this.setItem = -1;
                this.bookSelectedRowKeys = [];
                this.bookSelectedRow = [];
                this.SubTitle = '';
                this.PacksType = '';
                this.Tag = '';
                this.StartDate = null;
                this.EndDate = null;
                this.Objects = '';
                this.MaxBuy = 1;
                this.PacksImageUrl = '';
                this.checkedList = [];
            },
            _delTableRow(i){
                this.$axios.post(5836181,{
                    PackId: this.tableData[i].Id
                },res=>{
                    if(res.data.code==1){
                        this.$message.success(res.data.message);
                        this.tableData.splice(i,1);
                    }else{
                        this.$message.error(res.data.message);
                    }
                });
            },
            _reset(){
                this.searchStatus = '';
                this.searchName = '';
            },
            _searchData(){
                this._infoData(this.searchName,this.searchStatus,1)
            },
            _changePacksType(value){
                this.PacksType = value;
            },
            _changeObject(value){
                this.Objects = value;
            },
        }
    }
</script>

<style scoped lang="less">
    .ClassPackageAdmin{
        padding: 20px;
        height: 100%;
        background: #fff;
        border-radius: 10px;
        .search-item{
            margin-right: 20px;
            span{
                margin-right: 10px;
            }
        }
        .container{
            margin-top: 20px;
            .icon-btn{
                font-size: 20px;
                margin-right: 8px;
                cursor: pointer;
            }
        }
    }
    .status-icon{
        display: inline-block;
        vertical-align: middle;
        width: 8px;
        height: 8px;
        border-radius: 50%;
    }
    .alert-item{
        margin-top: 0;
        justify-content: end;
        margin-bottom: 20px;
        span{
            display: inline-block;
            width: 70px;
            vertical-align: middle;
            line-height: 32px;
        }
        .alert-input{
            margin-right: 15px;
            width:320px;
            vertical-align: middle;
        }
    }
    .alert-footer{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        border-top: 1px solid #e9e9e9;
        padding: 10px 16px;
        background: #fff;
        button{
            width: 120px;
        }
    }
</style>

<template>
    <div class="CourseAdminIndex">
        <div class="tabs">
            <ul>
                <li @click="_tabsActive(0)" :class="tabsActive==0?'active':''">课程管理</li>
                <li @click="_tabsActive(1)" :class="tabsActive==1?'active':''">课包管理</li>
            </ul>
        </div>

        <div class="content">
            <CourseAdmin v-if="tabsActive == 0"></CourseAdmin>
            <ClassPackageAdmin v-if="tabsActive == 1"></ClassPackageAdmin>
            <buyList v-if="tabsActive==2"></buyList>
        </div>
    </div>
</template>

<script>
    import CourseAdmin from './CourseAdmin';
    import ClassPackageAdmin from './ClassPackageAdmin';
    export default {
        name: "CourseAdminIndex",
        components: {
            CourseAdmin,ClassPackageAdmin
        },
        data(){
            return{
                tabsActive: 0
            }
        },
        methods: {
            _tabsActive(i){
                this.tabsActive = i;
            },
        }
    }
</script>

<style scoped lang="less">
    .CourseAdminIndex{
        height: 100%;
        .tabs{
            margin-bottom: 20px;
            border-bottom: 1px solid #CCD1DC;
            border-left: 1px solid #CCD1DC;
            border-top-left-radius: 5px;
            ul{
                li{
                    display: inline-block;
                    width: 85px;
                    padding: 6px 0;
                    text-align: center;
                    border: 1px solid #CCD1DC;
                    border-bottom: none;
                    cursor: pointer;
                    border-left: 0;
                    &:first-child{
                        border-top-left-radius: 5px;
                    }
                    &:last-child{
                        border-top-left-radius: 0;
                        border-top-right-radius: 5px;
                    }
                }
                .active{
                    background: #1890FF;
                    color: #fff;
                    border: 1px solid #1890FF;
                }
            }
        }
        .content{
            height: calc(~'100% - 60px');
        }
    }
</style>

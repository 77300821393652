<template>
    <div class="CourseAdmin flex" style="align-items: end">
        <div class="tree">
            <div>
                <a-tree :treeData="homeBookClassify" defaultExpandAll @select="_homeBookClassify"/>
            </div>
        </div>
        <!--首页内容-->
        <div v-if="!showAddPage" class="content" style="flex: 1;margin-left: 15px;">
            <div class="search-div flex">
                <div class="flex" style="flex: 1;justify-content: end">
                    <div class="search-item">
                        <span>状态</span>
                        <a-select style="width: 100px" v-model="searchStatus">
                            <a-select-option value="">全部</a-select-option>
                            <a-select-option value="1">启用</a-select-option>
                            <a-select-option value="0">禁用</a-select-option>
                        </a-select>
                    </div>

                    <div class="search-item" style="white-space: nowrap">
                        <span>名称</span>
                        <a-input v-model="searchName" style="width: 200px;" placeholder="搜索名称"></a-input>
                    </div>

                    <a-button style="margin-right: 20px;" @click="_reset()">重置</a-button>
                    <a-button type="primary" @click="_searchData">查询</a-button>
                </div>
                <div>
                    <a-button type="primary" style="width:90px" @click="_showAddPage">新建</a-button>
                </div>
            </div>

            <div class="container">
                <a-table bordered :columns="tableColumns" :dataSource="tableData" :pagination="false">
                    <div slot="ThumbUrl" slot-scope="text, record, index">
                        <img :src="text+'?imageView2/1/w/80/h/80'" style="width: 40px;height: 40px;" alt="">
                    </div>
                    <div slot="status" slot-scope="text, record, index">
                        <span class="status-icon" :style="text=='禁用'?'background: #E81B1C':'background: #4199F3'"></span> {{text}}
                    </div>
                    <div slot="operation" slot-scope="text, record, index" style="width:100px">
                        <i class="iconfont icon-bianji icon-btn" style="font-size: 20px" @click='_setItem(index)'></i>
                        <a-popconfirm title="您确定要删除吗?" @confirm="_delTableRow(index)" okText="确定" cancelText="取消">
                            <i class="iconfont icon-shanchu- icon-btn"></i>
                        </a-popconfirm>
                    </div>
                </a-table>
                <div class="pagination" v-if="tableDataCount>10">
                    <a-pagination :total="tableDataCount" style="display: inline-block;margin-top:20px;" @change="_tablePage" />
                </div>
            </div>
        </div>

        <!--添加弹框-->
        <div v-else style="flex: 1;margin-left: 15px;min-height: 100%">
            <!--练习册详情-->
            <div style="flex:1;min-height: 100%">
                <div class="crumbs" style="margin-bottom: 15px;">
                    <a-icon class="return" @click="_return" type="left" />
                    <div style="position: absolute;top:0; right: 25px;height:100%">
                        <a-button type="primary" @click="_saveCourse">保存</a-button>
                    </div>
                    <!--<div style="position: absolute;top:0; right: 25px;height:100%" v-else @click="_setBook">-->
                    <!--<a-button type="primary">修改</a-button>-->
                    <!--</div>-->
                </div>
                <div class="workBook-detail flex" style="align-items: end">
                    <!--基本信息-->
                    <div class="tree" style="width: 300px;">
                        <div class="tree-title">基本信息</div>
                        <div class="row">
                            <p>标题</p>
                            <a-input placeholder="请输入" v-model="bookName"></a-input>
                        </div>

                        <div class="row">
                            <p>课程图标</p>
                            <a-button style="position: relative">上传图片 <input type="file" style="position: absolute;width: 100%;height: 100%;opacity: 0;top:0;left:0"  @change="_uploadCourceIcon($event)"></a-button>
                        </div>

                        <div class="row" v-if="bookIconUrl">
                            <p></p>
                            <img :src="bookIconUrl + '?imageView2/0/w/60'" alt="">
                        </div>

                        <div class="row">
                            <p>年龄范围</p>
                            <a-cascader :options="ageList" v-model="ageBracket"  placeholder="请选择" />
                        </div>

                        <div class="row">
                            <p>副标题</p>
                            <a-input placeholder="请输入" v-model="ReserveBookName"></a-input>
                        </div>

                        <div class="row">
                            <p>是否发布</p>
                            <div style="flex: 1">
                                <div style="display: inline-block;width: 50%;vertical-align: middle">
                                    <a-checkbox :checked="bookIsRec ==1?true:false" @click="bookIsRec = 1">是</a-checkbox>
                                </div>
                                <div style="display: inline-block;width: 50%;vertical-align: middle">
                                    <a-checkbox :checked="bookIsRec == 0? true: false" @click="bookIsRec = 0">否</a-checkbox>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <p>课程原价</p>
                            <a-input-number placeholder="请输入" v-model="OrgPrice"></a-input-number>
                        </div>

                        <div class="row">
                            <p>课程价格</p>
                            <a-input-number placeholder="请输入" v-model="CoursePrice"></a-input-number>
                        </div>

                        <div class="row">
                            <p>课程编码</p>
                            <a-input placeholder="请输入" v-model="CourseCode"></a-input>
                        </div>

                        <div class="row">
                            <p>展示方式</p>
                            <a-select style="width: 120px" @change="_changeType" v-model="Type">
                                <a-select-option value="分日展示">分日展示</a-select-option>
                                <a-select-option value="一次性展示">一次性展示</a-select-option>
                            </a-select>
                        </div>

                        <div class="row" style="align-items: end">
                            <p>课程介绍</p>
                            <textarea v-model="bookIntro" style="flex:1;height: 150px;border:1px solid #CCD1DC;border-radius: 4px;resize: none;padding: 5px;"></textarea>
                        </div>
                    </div>
                    <!--题目大纲-->
                    <div class="tree" style="width: 250px">
                        <div class="tree-title flex">
                            <span>目录</span>
                            <a-icon type="plus-circle" style="font-size: 16px;margin-left:3px;color:rgba(0, 0, 0, 0.65);cursor: pointer" @click="_addTree('')"/>
                        </div>
                        <a-tree defaultExpandAll @select="_getQuestionData">
                            <template v-for="(item,index) in bookCategoryData[0].Children">
                                <a-icon slot="icon" type="carry-out" />
                                <a-tree-node :key="item.Key">
                                    <a-icon slot="icon" type="carry-out" />
                                    <div slot="title" style="width: 100%" class="flex">
                                        <span>{{item.Name}}</span>
                                        <span v-show="treeSelected==item.Key">
                                                <a-icon type="plus-circle" style="font-size: 16px;margin-right:3px;color:rgba(0, 0, 0, 0.65)" @click.stop="_addTree([index])"/>
                                                <i class="iconfont icon-bianji icon-btn" style="font-size: 16px;margin-right:3px" @click.stop="_setTree([index],item.Name,item)"></i>
                                                <a-popconfirm placement="bottom" title="您确定要删除吗?" @confirm="_delTree([index])" okText="确定" cancelText="取消" >
                                                    <i class="iconfont icon-shanchu- icon-btn" @click.stop></i>
                                                </a-popconfirm>
                                            </span>
                                    </div>

                                    <template v-if="item.Children" v-for="(tab,tabIndex) in item.Children">
                                        <a-icon slot="icon" type="carry-out" />
                                        <a-tree-node :key="tab.Key">
                                            <a-icon slot="icon" type="carry-out" />
                                            <div slot="title" style="width: 100%" class="flex">
                                                <span>{{tab.Name}}</span>
                                                <span v-show="treeSelected==tab.Key">
                                                    <a-icon type="plus-circle" style="font-size: 16px;margin-right:3px;color:rgba(0, 0, 0, 0.65)" @click.stop="_addTree([index,tabIndex])"/>
                                                    <i class="iconfont icon-bianji icon-btn" style="font-size: 16px;margin-right:3px" @click.stop="_setTree([index,tabIndex],tab.Name,tab)"></i>
                                                    <!-- <i class="iconfont icon-shanchu- icon-btn" @click.stop="_delTree([index,tabIndex])"></i> -->
                                                    <a-popconfirm placement="bottom" title="您确定要删除吗?" @confirm="_delTree([index,tabIndex])" okText="确定" cancelText="取消">
                                                        <i class="iconfont icon-shanchu- icon-btn" @click.stop></i>
                                                    </a-popconfirm>
                                                </span>
                                            </div>

                                            <template v-if="tab.Children" v-for="(tab2,tab2Index) in tab.Children">
                                                <a-icon slot="icon" type="carry-out" />
                                                <a-tree-node :key="tab2.Key">
                                                    <a-icon slot="icon" type="carry-out" />
                                                    <div slot="title" style="width: 100%" class="flex">
                                                        <span>{{tab2.Name}}</span>
                                                        <span v-show="treeSelected==tab2.Key">
                                                            <a-icon type="plus-circle" style="font-size: 16px;margin-right:3px;color:rgba(0, 0, 0, 0.65)" @click.stop="_addTree([index,tabIndex,tab2Index])"/>
                                                            <i class="iconfont icon-bianji icon-btn" style="font-size: 16px;margin-right:3px" @click.stop="_setTree([index,tabIndex,tab2Index],tab2.Name,tab2)"></i>
                                                            <!-- <i class="iconfont icon-shanchu- icon-btn" @click.stop="_delTree([index,tabIndex,tab2Index])"></i> -->

                                                            <a-popconfirm placement="bottom" title="您确定要删除吗?" @confirm="_delTree([index,tabIndex,tab2Index])" okText="确定" cancelText="取消">
                                                                <i class="iconfont icon-shanchu- icon-btn" @click.stop></i>
                                                            </a-popconfirm>
                                                        </span>
                                                    </div>

                                                    <template v-if="tab2.Children" v-for="(tab3,tab3Index) in tab2.Children">
                                                        <a-icon slot="icon" type="carry-out" />
                                                        <a-tree-node :key="tab3.Key">
                                                            <a-icon slot="icon" type="carry-out" />
                                                            <div slot="title" style="width: 100%" class="flex">
                                                                <span>{{tab3.Name}}</span>
                                                                <span v-show="treeSelected==tab3.Key">
                                                                    <!--<a-icon type="plus-circle" style="font-size: 16px;margin-right:3px;color:rgba(0, 0, 0, 0.65)" @click.stop="_addTree([index,tabIndex,tab2Index,tab3Index])"/>-->
                                                                    <i class="iconfont icon-bianji icon-btn" style="font-size: 16px;margin-right:3px" @click.stop="_setTree([index,tabIndex,tab2Index,tab3Index],tab3.Name,tab3)"></i>
                                                                    <!-- <i class="iconfont icon-shanchu- icon-btn" @click.stop="_delTree([index,tabIndex,tab2Index,tab3Index])"></i> -->

                                                                    <a-popconfirm placement="bottom" title="您确定要删除吗?" @confirm="_delTree([index,tabIndex,tab2Index,tab3Index])" okText="确定" cancelText="取消">
                                                                        <i class="iconfont icon-shanchu- icon-btn" @click.stop></i>
                                                                    </a-popconfirm>
                                                                </span>
                                                            </div>
                                                        </a-tree-node>
                                                    </template>
                                                </a-tree-node>
                                            </template>

                                        </a-tree-node>
                                    </template>

                                </a-tree-node>
                            </template>
                        </a-tree>
                    </div>
                    <!--题目信息-->
                    <div class="tree" style="flex: 1;margin-right:0" v-if="treeSelected != ''">
                        <div class="flex">
                            <div class="tree-title"></div>
                            <div>
                                <a-button type="primary"  style="margin-right: 10px;" @click="_showAddCourseAlert">添加课程</a-button>
                            </div>
                        </div>

                        <div style="margin-top: 20px;user-select: none;cursor: all-scroll">
                            <a-table bordered :columns="workBookDetailTableColumns" :dataSource="workBookTableColumnsData" :pagination="false">
                                <!-- :customRow="click" -->
                                <div slot="ThumbUrl" slot-scope="text, record, index">
                                    <img :src="text+'?imageView2/1/w/60/h/60'" style="width: 30px;height:30px;" alt="">
                                </div>
                                <div slot="vUrl" slot-scope="text, record, index" class="ellipsis">
                                    <a-tooltip placement="topLeft">
                                        <template slot="title">
                                          {{record.vUrl}}
                                        </template>
                                        {{record.vUrl}}
                                    </a-tooltip>
                                </div>
                                <div slot="State" slot-scope="text, record, index">
                                    <span class="status-icon" :style="text=='禁用'?'background: #E81B1C':'background: #4199F3'"></span> {{text}}
                                </div>
                                <div slot="operation" slot-scope="text, record, index">
                                    <i class="iconfont icon-bianji icon-btn" style="font-size: 18px;margin-right: 5px;" @click.stop="_questionShow(index)"></i>
                                    <a-popconfirm title="您确定要删除吗?" @confirm="_delworkBookDetailTabl(index,0)" okText="确定" cancelText="取消">
                                        <i class="iconfont icon-shanchu- icon-btn"></i>
                                    </a-popconfirm>
                                </div>
                            </a-table>
                            <!--<div class="pagination" v-if="tableDataCount>10">-->
                            <!--<a-pagination :total="tableDataCount" style="display: inline-block;margin-top:20px;" @change="_tablePage" />-->
                            <!--</div>-->
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <!--练习题创建新节点-->
        <a-drawer title="节点设置" placement="right" width="400" :closable="false" @close="_newTreeNodeClose" :visible="TreeInput">
            <div style="padding-bottom: 55px;height: 100%">
                <div class="container" style="margin-top: 0;justify-content: end">
                    <span style="display: inline-block;width: 70px;vertical-align: middle;line-height: 32px">节点名称</span> <a-input style="margin-right: 15px;width:220px;vertical-align: middle" v-model="addTreeInput"></a-input>
                </div>

                <div class="alert-footer">
                    <a-button style="margin-right:25px" @click="_newTreeNodeClose">取消</a-button>
                    <a-button type="primary" @click.stop="_confirmAddTree">确定</a-button>
                </div>
            </div>
        </a-drawer>

        <!--添加课程-->
        <a-drawer title="添加课程" placement="right" width="600" :closable="false" @close="_addCourseClose" :visible="addCourseDrawer">
            <div style="padding-bottom: 55px;height: 100%">
                <div class="container alert-item">
                    <span style="">主题</span> <a-input class="alert-input" v-model="addCourseName"></a-input>
                </div>

                <div class="container alert-item">
                    <span style="">缩略图</span> <a-button style="position: relative">上传图片 <input type="file" style="position: absolute;width: 100%;height: 100%;opacity: 0;top:0;left:0"  @change="_uploadCourceIcon2($event)"></a-button>
                </div>
                <div class="container alert-item" v-if="ThumbUrl">
                    <span style=""></span>
                    <img :src="ThumbUrl" alt="" style="width: 200px;height:200px;">
                </div>

                <div class="container alert-item">
                    <span style="">排序</span> <a-input class="alert-input" v-model="addCourseSort"></a-input>
                </div>

                <div class="container alert-item">
                    <span style="">内容类型</span>
                    <a-select style="width: 220px" @change="_changeMediaType" v-model="MediaType">
                        <a-select-option value="视频">视频</a-select-option>
                        <a-select-option value="音频">音频</a-select-option>
                        <!-- <a-select-option value="图文">图文</a-select-option> -->
                    </a-select>
                </div>

                <div class="container alert-item">
                    <span style="">是否免费</span>
                    <a-select style="width: 220px" @change="_changeAudition" v-model="Audition">
                        <a-select-option value="否">否</a-select-option>
                        <a-select-option value="是">是</a-select-option>
                    </a-select>
                </div>

                <div class="container alert-item">
                    <span style="">播放地址</span> <a-input class="alert-input" v-model="addCourseAudio" style="width:400px"></a-input>
                </div>

                <div class="container alert-item">
                    <span style="">播放日期</span>
                    <a-date-picker style="width: 220px;" v-model="PlayDate" placeholder="请选择"/>
                </div>

                <div class="container alert-item">
                    <span style="vertical-align: top">详情介绍</span> <textarea class="alert-input" v-model="section_content" style="flex:1;height: 150px;border:1px solid #CCD1DC;border-radius: 4px;resize: none;padding: 5px;width:400px;"></textarea>
                </div>

                <div class="container alert-item">
                    <span style="">是否发布</span>
                    <div class="alert-input" style="display: inline-block">
                        <div style="display: inline-block;width: 50%;vertical-align: middle">
                            <a-checkbox :checked="addCourseIssue ==1?true:false" @click="addCourseIssue = 1">是</a-checkbox>
                        </div>
                        <div style="display: inline-block;width: 50%;vertical-align: middle">
                            <a-checkbox :checked="addCourseIssue == 0? true: false" @click="addCourseIssue = 0">否</a-checkbox>
                        </div>
                    </div>
                </div>

                <div class="alert-footer">
                    <a-button style="margin-right:25px" @click="_addCourseClose">取消</a-button>
                    <a-button type="primary" @click.stop="_addCourseSave">确定</a-button>
                </div>
            </div>
        </a-drawer>
    </div>
</template>

<script>
    export default {
        name: "CourseAdmin",
        data(){
            return {
                searchStatus: '',
                searchName: '',
                tabsActive: 0,
                tableDataCount: 0,
                tableColumns: [
                    {
                        title: '序号',
                        dataIndex: 'sNumber',
                        width: 70,
                        align: 'center',
                    },
                    {
                        title: '缩略图',
                        dataIndex: 'ThumbUrl',
                        width: 110,
                        align: 'center',
                        scopedSlots: { customRender: 'ThumbUrl' },
                    },
                    {
                        title: '题目',
                        dataIndex: 'Title',
                    },
                    {
                        title: '分类',
                        dataIndex: 'CategoryName',
                        width: 250,
                        align: 'center'
                    },
                    {
                        title: '价格',
                        dataIndex: 'Price',
                        width: 120,
                        align: 'center'
                    },
                    {
                        title: '状态',
                        dataIndex: 'State',
                        width: 110,
                        align: 'center',
                        scopedSlots: { customRender: 'status' },
                    },
                    {
                        title: '创建人',
                        dataIndex: 'CreateUserName',
                        width: 120,
                        align: 'center'
                    },
                    {
                        title: '上传时间',
                        dataIndex: 'Create_time',
                        width: 230,
                        align: 'center'
                    },
                    {
                        title: '操作',
                        dataIndex: 'operation',
                        width: 100,
                        align: 'center',
                        scopedSlots: { customRender: 'operation' },
                    }
                ],
                tableData: [],
                showAddPage: false,

                bookName: '',
                bookIconUrl: '',
                ageList: [], //年龄段数据
                ageBracket: [], //年龄段
                bookCourseDisplay: 1, //课程是否显示
                bookIntro: '',
                bookIsRec: 1, //是否推荐
                ReserveBookName: '',
                addClassify: '',
                CoursePrice: '',
                OrgPrice:'',
                CourseCode: '',
                CourseId: '',
                bookCategoryData: [
                    {
                        Name: '目录',
                        Key: 'maxNode',
                        Pid: '',
                        Id: '',
                        Children: [

                        ]
                    },
                ], //树节点
                setTree: -1, //要修改的节点名称
                addTree: -1,
                addTreeInput: '',
                TreeInput: false, //是否显示修改框
                treeSelected: '',  //选择的节点ID

                workBookDetailTableColumns: [
                    {
                        title: '题号',
                        dataIndex: 'sNumber',
                        width: 70,
                        align: 'center'
                    },
                    {
                        title: '缩略图',
                        dataIndex: 'ThumbUrl',
                        width: 110,
                        align: 'center',
                        scopedSlots: { customRender: 'ThumbUrl' },
                    },
                    {
                        title: '主题',
                        dataIndex: 'Title',
                        align: 'center',
                    },
                    {
                        title: '排序',
                        dataIndex: 'Sort',
                        width: 80,
                        align: 'center',
                    },
                    {
                        title: '视频地址',
                        dataIndex: 'vUrl',
                        width: 210,
                        align: 'center',
                        scopedSlots: { customRender: 'vUrl' },
                    },
                    {
                        title: '状态',
                        dataIndex: 'State',
                        width: 110,
                        align: 'center',
                        scopedSlots: { customRender: 'State' },
                    },
                    {
                        title: '操作',
                        dataIndex: 'operation',
                        width: 100,
                        align: 'center',
                        scopedSlots: { customRender: 'operation' },
                    }
                ],

                workBookTableColumnsData: [],

                addCourseDrawer: false,
                addCourseName: '',
                addCourseSort: '',
                addCourseAudio: '',
                addCourseIssue: 0,
                ThumbUrl: '',

                treeSelect: '',
                homeBookClassify: '',
                setItem: -1,
                questionShow: -1,
                Type:'',
                MediaType:'',
                PlayDate:null,
                section_content:'',
                Audition:'否',
            }
        },
        created(){
            let self = this;
            //获取年龄段数据
            for(let i = 0;i < 13;i++){
                self.ageList.push( {value: i,label: i ,children: []});
                for(let j = i+1;j < 13;j++){
                    self.ageList[i].children.push({value: j ,label: j ,})
                }
            }
            //获取分类
            this.$axios.get(1206,{label:'classification'},res=>{
                if(res.data.code==1){
                    let data = [{
                        title: '全部分类',
                        children: res.data.data,
                        key: '',
                        value: '',
                        Id: '',
                    }];
                    this.homeBookClassify = data;
                }
            });

            this._infoData('',1,'','')
        },
        methods: {
            _saveCourse(){
                this.$axios.post(5836172,{
                    CourseId: this.CourseId,
                    CategoryId: this.treeSelect,
                    Title: this.bookName,
                    SubTitle: this.ReserveBookName,
                    ThumbUrl: this.bookIconUrl,
                    Year: this.ageBracket[0],
                    Month: this.ageBracket[1],
                    Week: this.ageBracket[2],
                    Price: this.CoursePrice,
                    Code: this.CourseCode,
                    State: this.bookIsRec,
                    Intro: this.bookIntro,
                    Type:'分日展示' == this.Type ? 1 : 2,
                    OrgPrice:this.OrgPrice,
                },res=>{
                    if(res.data.code==1){
                        this.$message.success(res.data.message);
                        let item = {
                            Id: res.data.id,
                            Code: this.CourseCode,
                            ThumbUrl: this.bookIconUrl,
                            Title: this.bookName,
                            CategoryName: '',
                            Price: this.CoursePrice,
                            State: this.bookIsRec==1?'启用':'禁用',
                            CreateUserId: userInfo.uid,
                            CreateUserName: userInfo.name,
                            Create_time: "刚刚",
                        };
                        this.tableData.push(item);
                        this._return();
                    }else {
                        this.$message.error(res.data.message);
                    }
                    //this.$message.destroy();
                });
            },
            _setItem(i){
                this.setItem = i;
                this.showAddPage = true;
                this.$message.loading('加载中...');
                this.$axios.get(5836174,{Id: this.tableData[i].Id},res=>{
                    if(res.data.code==1){
                        let data = res.data.data.Course_info;
                        this.CourseId = data.Id;
                        this.bookName = data.Title;
                        this.ReserveBookName = data.SubTitle;
                        this.bookIconUrl = data.ThumbUrl;
                        this.ageBracket = [parseInt(data.Year),parseInt(data.Month),parseInt(data.Week)];
                        this.CoursePrice = data.Price;
                        this.CourseCode = data.Code;
                        this.bookIsRec = data.State;
                        this.bookIntro = data.Intro;
                        this.OrgPrice = data.OrgPrice;
                        this.Type = data.Type==1?'分日展示':'一次性展示';
                        this.bookCategoryData =  [
                            {
                                Name: '目录',
                                Key: 'maxNode',
                                Pid: '',
                                Id: '',
                                Children: res.data.data.Category_info
                            },
                        ]
                    }
                    this.$message.destroy();
                });
            },
            _homeBookClassify(key){
                this.treeSelect = key[0];
                if (this.setItem == -1) {
                    this._infoData(this.treeSelect,1,this.searchStatus,this.searchName);
                }
            },
            _infoData(CategoryId,Page,State,Title){
                this.$axios.get(5836170,{
                    CategoryId: CategoryId,
                    Page: Page,
                    State: State,
                    Title: Title,
                },res=>{
                    if(res.data.code==1){
                        this.tableData = res.data.data;
                        this.tableDataCount = res.data.count
                    }else{
                        this.tableData = [];
                        this.tableDataCount = 0;
                    }
                });
            },
            _delworkBookDetailTabl(i){
                this.$axios.post(5836179,{
                    CourseId: this.CourseId,
                    content_id: this.workBookTableColumnsData[i].Id
                },res=>{
                    if(res.data.code==1){
                        this.$message.success('删除成功');
                        this.workBookTableColumnsData.splice(i,1);
                    }else{
                        this.$message.error('删除失败');
                    }
                });
            },
            _delTableRow(i){
                this.$axios.post(5836173,{
                    CourseId: this.tableData[i].Id
                },res=>{
                    if(res.data.code==1){
                        this.$message.success('删除成功');
                        this.tableData.splice(i,1);
                    }else{
                        this.$message.error('删除失败');
                    }
                });
            },
            _delTree(index){
                let item;
                if(index.length==3){
                    item = this.bookCategoryData[0].Children[index[0]].Children[index[1]].Children[index[2]];
                }else if(index.length==2){
                    item = this.bookCategoryData[0].Children[index[0]].Children[index[1]];
                }else if(index.length==4){
                    item = this.bookCategoryData[0].Children[index[0]].Children[index[1]].Children[index[2]].Children[index[3]];
                }else{
                    item = this.bookCategoryData[0].Children[index[0]];
                }
                this.$axios.post(5836171,{
                    CourseId: this.CourseId,
                    Current_id: item.Id,
                    Pid: item.Pid,
                    type: 'del',
                    Name: this.addTreeInput,
                    Key: item.Key,
                },res=>{
                    if(res.data.code==1){
                        this.$message.success('删除成功');
                        if(index.length==3){
                            this.bookCategoryData[0].Children[index[0]].Children[index[1]].Children.splice(index[2],1);
                        }else if(index.length==2){
                            this.bookCategoryData[0].Children[index[0]].Children.splice(index[1],1);
                        }else if(index.length==4){
                            this.bookCategoryData[0].Children[index[0]].Children[index[1]].Children[index[2]].Children.splice(index[3],1);
                        }else{
                            this.bookCategoryData[0].Children.splice(index[0],1);
                        }
                    }else{
                        this.$message.error(res.data.message);
                    }
                });
                // this.bookCategoryData[0].Children.map((item_1,index)=>{
                //     item_1.Key = index;
                //     if(item_1.Children.length > 0){
                //         item_1.Children.map((item_2,index_2)=>{
                //             item_2.Key = index + '-' + index_2;
                //
                //             if(item_2.Children.length > 0){
                //                 item_2.Children.map((item_3,index_3)=>{
                //                     item_3.Key = index + '-' + index_2 + '-' + index_3;
                //
                //                     if(item_3.Children.length > 0){
                //                         item_3.Children.map((item_4,index_4)=>{
                //                             item_4.Key = index + '-' + index_2 + '-' + index_3+ '-' + index_4;
                //                         })
                //                     }
                //                 })
                //             }
                //         })
                //     }
                // });
            },
            _showAddCourseAlert(){
                this.addCourseDrawer = true;
            },
            _addCourseClose(){
                this.addCourseDrawer = false;
                this.addCourseName = '';
                this.addCourseSort = '';
                this.addCourseAudio = '';
                this.addCourseIssue = 0;
                this.ThumbUrl = '';
                this.PlayDate = '';
                this.section_content = '';
                this.Audition = '否';

            },
            _confirmAddTree(){
                if(this.addTreeInput == ''){
                    return;
                }
                if(this.setTree === -1){
                    let item = {
                        Name: this.addTreeInput,
                        Key: '',
                        Pid: '',
                        Id: '',
                        Children: []
                    };
                    if(this.addTree == ''){
                        item.Pid = '';
                    }else if(this.addTree.length==1){
                        item.Pid = this.bookCategoryData[0].Children[this.addTree[0]].Id;
                    }else if(this.addTree.length==2){
                        item.Pid = this.bookCategoryData[0].Children[this.addTree[0]].Children[this.addTree[1]].Id;
                    }else if(this.addTree.length==3){
                        item.Pid = this.bookCategoryData[0].Children[this.addTree[0]].Children[this.addTree[1]].Children[this.addTree[2]].Id;
                    }
                    this.$axios.post(5836171,{
                        CourseId: this.CourseId,
                        Current_id: '',
                        Pid: item.Pid,
                        type: 'add',
                        Name: item.Name,
                        Key: item.Key,
                    },res=>{
                        if(res.data.code==1){
                            item.Id = res.data.categoryData.tmpNewCatetoryId;
                            item.Key = res.data.categoryData.tmpNewCatetoryId;
                            this.CourseId = res.data.categoryData.tmpCourseId;
                            if(this.addTree == ''){
                                this.bookCategoryData[0].Children.push(item);
                            }else if(this.addTree.length==1){
                                this.bookCategoryData[0].Children[this.addTree[0]].Children.push(item);
                            }else if(this.addTree.length==2){
                                this.bookCategoryData[0].Children[this.addTree[0]].Children[this.addTree[1]].Children.push(item);
                            }else if(this.addTree.length==3){
                                this.bookCategoryData[0].Children[this.addTree[0]].Children[this.addTree[1]].Children[this.addTree[2]].Children.push(item);
                            }

                        }
                    });
                }else {
                    let key,id,pid;
                    if(this.setTree.length==1){
                        key = this.bookCategoryData[0].Children[this.setTree[0]].Key;
                        id = this.bookCategoryData[0].Children[this.setTree[0]].Id;
                        pid = this.bookCategoryData[0].Children[this.setTree[0]].Pid;
                    }else if(this.setTree.length==2){
                        key = this.bookCategoryData[0].Children[this.setTree[0]].Children[this.setTree[1]].Key;
                        id = this.bookCategoryData[0].Children[this.setTree[0]].Children[this.setTree[1]].Id;
                        pid = this.bookCategoryData[0].Children[this.setTree[0]].Children[this.setTree[1]].Pid;
                    }else if(this.setTree.length==3){
                        key = this.bookCategoryData[0].Children[this.setTree[0]].Children[this.setTree[1]].Children[this.setTree[2]].Key;
                        id = this.bookCategoryData[0].Children[this.setTree[0]].Children[this.setTree[1]].Children[this.setTree[2]].Id;
                        pid = this.bookCategoryData[0].Children[this.setTree[0]].Children[this.setTree[1]].Children[this.setTree[2]].Pid;
                    }else if(this.setTree.length==4){
                        key = this.bookCategoryData[0].Children[this.setTree[0]].Children[this.setTree[1]].Children[this.setTree[2]].Children[this.setTree[3]].Key;
                        id = this.bookCategoryData[0].Children[this.setTree[0]].Children[this.setTree[1]].Children[this.setTree[2]].Children[this.setTree[3]].Id;
                        pid = this.bookCategoryData[0].Children[this.setTree[0]].Children[this.setTree[1]].Children[this.setTree[2]].Children[this.setTree[3]].Pid;
                    }
                    this.$axios.post(5836171,{
                        CourseId: this.CourseId,
                        Current_id: id,
                        Pid: pid,
                        type: 'edit',
                        Name: this.addTreeInput,
                        Key: key,
                    },res=>{
                        if(res.data.code==1){
                            if(this.setTree.length==1){
                                this.bookCategoryData[0].Children[this.setTree[0]].Name = this.addTreeInput;
                            }else if(this.setTree.length==2){
                                this.bookCategoryData[0].Children[this.setTree[0]].Children[this.setTree[1]].Name = this.addTreeInput;
                            }else if(this.setTree.length==3){
                                this.bookCategoryData[0].Children[this.setTree[0]].Children[this.setTree[1]].Children[this.setTree[2]].Name = this.addTreeInput;
                            }else if(this.setTree.length==4){
                                this.bookCategoryData[0].Children[this.setTree[0]].Children[this.setTree[1]].Children[this.setTree[2]].Children[this.setTree[3]].Name = this.addTreeInput;
                            }
                        }else{
                            this.$message.error(res.data.message);
                        }
                    });
                }
                this.TreeInput = false;
            },
            _setTree(i,name,data){
                this.addTreeInput = name;
                this.setTree = i;
                this.TreeInput = true;
            },
            _addTree(i){
                this.addTree = i;
                this.TreeInput = true;
                this.addTreeInput = '';
            },
            _newTreeNodeClose(){
                this.TreeInput = false;
                this.addTree = -1;
                this.addTreeInput = '';
            },
            _getQuestionData(selectedKeys,e){
                if(selectedKeys.length==0){
                    return false;
                }
                if(selectedKeys.length!=0){
                    this.treeSelected = selectedKeys[0];
                }else {
                    this.treeSelected = '';
                }
                this.$message.loading('加载中...');
                this.$axios.get(5836175,{
                    CourseId: this.CourseId,
                    CategoryId: this.treeSelected
                },res=>{
                    if(res.data.code==1){
                        this.workBookTableColumnsData = res.data.data;
                    }else{
                        this.workBookTableColumnsData = [];
                    }
                    this.$message.destroy();
                });
            },
            _addCourseSave(){
                this.$axios.post(5836177,{
                    content_id: this.questionShow == -1?'':this.workBookTableColumnsData[this.questionShow].Id,
                    CourseId: this.CourseId,
                    CategoryId: this.treeSelected,
                    Title: this.addCourseName,
                    ThumbUrl: this.ThumbUrl,
                    Sort: this.addCourseSort,
                    vUrl: this.addCourseAudio,
                    State: this.addCourseIssue,
                    Audition:'否' == this.Audition?0:1,
                    Content:this.section_content,
                    PlayDate:this.PlayDate?this.__moment__(this.PlayDate).format('YYYY-MM-DD'):'',
                    MediaType:this.MediaType,
                },res=>{
                    if(res.data.code==1){
                        this.addCourseDrawer = false;
                        if(this.questionShow == -1){
                            let item = {
                                Id: res.data.id,
                                sNumber: this.workBookTableColumnsData.length,
                                Title: this.addCourseName,
                                ThumbUrl: this.ThumbUrl,
                                Sort: this.addCourseSort,
                                vUrl: this.addCourseAudio,
                                State: this.addCourseIssue==1?'启用':'禁用'
                            };
                            this.workBookTableColumnsData.push(item);
                        }else{
                            this.workBookTableColumnsData[this.questionShow].Title = this.addCourseName;
                            this.workBookTableColumnsData[this.questionShow].ThumbUrl = this.ThumbUrl;
                            this.workBookTableColumnsData[this.questionShow].Sort = this.addCourseSort;
                            this.workBookTableColumnsData[this.questionShow].vUrl = this.addCourseAudio;
                            this.workBookTableColumnsData[this.questionShow].State = this.addCourseIssue == 1?'启用':'禁用';
                        }
                        this.addCourseName = '';
                        this.ThumbUrl = '';
                        this.addCourseSort = '';
                        this.addCourseAudio = '';
                        this.addCourseIssue = 0;
                        this.questionShow = -1;
                        this.$message.success(res.data.message);
                    }else{
                        this.$message.error(res.data.message);
                    }
                });
            },
            _questionShow(i){
                this.questionShow = i;
                this.$axios.get(5836178,{
                    Id: this.workBookTableColumnsData[i].Id
                },res=>{
                    if(res.data.code==1){
                        this.addCourseDrawer = true;
                        this.addCourseName = res.data.data.Title;
                        this.ThumbUrl = res.data.data.ThumbUrl;
                        this.addCourseSort = res.data.data.Sort;
                        this.addCourseAudio = res.data.data.vUrl;
                        this.addCourseIssue = res.data.data.State;
                    }
                });
            },
            _uploadCourceIcon2(e){
                this.__uploadFile__(e.target.files).then(res=>{
                    this.ThumbUrl = res[0].FileUrl;
                });
            },
            _uploadCourceIcon(e){
                this.__uploadFile__(e.target.files).then(res=>{
                    this.bookIconUrl = res[0].FileUrl;
                });
            },
            _return(){
                this.showAddPage = false;
                this.CourseId = '';
                this.bookName = '';
                this.ReserveBookName = '';
                this.bookIconUrl = '';
                this.ageBracket = '';
                this.CoursePrice = '';
                this.CourseCode = '';
                this.bookIsRec = '';
                this.bookIntro = '';
                this.treeSelected = '';
                this.questionShow = -1;
                this.OrgPrice = 0;
                this.bookCategoryData = [
                    {
                        Name: '目录',
                        Key: 'maxNode',
                        Pid: '',
                        Id: '',
                        Children: []
                    },
                ]
            },
            _showAddPage(){
                this.showAddPage = true;
            },
            _tablePage(page){
                this._infoData(this.treeSelect,page,this.searchStatus,this.searchName);
            },
            _searchData(){
                this._infoData(this.treeSelect,1,this.searchStatus,this.searchName);
            },
            _reset(){
                this.searchStatus = '';
                this.searchName = '';
            },
            _changeType(value){
                this.Type = value;
            },
            _changeMediaType(value){
                this.MediaType = value;
            },
            _changeAudition(value){
                this.Audition = value;
            },
        }
    }
</script>

<style scoped lang="less">
    .CourseAdmin{
        height: 100%;
        .status-icon{
            display: inline-block;
            vertical-align: middle;
            width: 8px;
            height: 8px;
            border-radius: 50%;
        }
        .content{
            padding: 20px;
            background: #fff;
            border-radius: 10px;
            min-height: 100%;
            .search-item{
                margin-right: 20px;
                span{
                    margin-right: 10px;
                }
            }
            .container{
                margin-top: 20px;
                .icon-btn{
                    font-size: 20px;
                    margin-right: 8px;
                    cursor: pointer;
                }
            }
            .pagination{
                text-align: right;
            }
        }

        .crumbs{
            position: relative;
            height: 64px;
            padding-right: 30px;
            line-height: 64px;
            border-radius: 4px;
            background: #fff;
            text-align: center;
            color: #666;
            font-size: 14px;
            .return{
                position: absolute;
                top: 50%;
                left: 25px;
                transform: translateY(-50%);
                font-size: 18px;
                color: #9D9D9D;
                cursor: pointer;
            }
            .crumbs-icon{
                margin-left: 30px;
                margin-right: 30px;
                font-size: 14px;
                color: #9D9D9D;
            }
            .crumbs-item{
                position: relative;
                cursor: pointer;
                span{
                    position: absolute;
                    bottom: -24px;
                    left: 0;
                    width: 100%;
                    height: 2px;
                }
            }
        }
        .workBook-detail{
            width: 100%;
            height: calc(~'100% - 80px');
            justify-content:end;
            .tree{
                display: inline-block;
                vertical-align: top;
                margin-right: 10px;
                height:100%;
                .row{
                    display: flex;
                    align-items: center;
                    margin: 20px 0;
                    p{
                        width: 70px;
                        color: #ABB0BF;
                    }
                    input{
                        flex: 1
                    }
                }
            }
        }
        .tree{
            width: 200px;
            padding: 15px;
            border-radius: 5px;
            background: #fff;
            height:100%;
            .tree-title{

            }
        }
    }
    .alert-footer{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        border-top: 1px solid #e9e9e9;
        padding: 10px 16px;
        background: #fff;
        button{
            width: 120px;
        }
    }
    .alert-item{
        margin-top: 0;
        justify-content: end;
        margin-bottom: 20px;
        span{
            display: inline-block;
            width: 70px;
            vertical-align: middle;
            line-height: 32px;
        }
        .alert-input{
            margin-right: 15px;
            width:220px;
            vertical-align: middle;
        }
    }
    .tree{
        width: 200px;
        min-height: 755px;
        padding: 15px;
        border-radius: 5px;
        background: #fff;
        .tree-title{

        }
    }
    .ellipsis{
        overflow : hidden;
        text-overflow: ellipsis;
        width:200px;
        white-space: nowrap;
    }
</style>
